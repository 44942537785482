import { Card } from "@components/Card";
import { NewUserPlaceholder } from "@components/NewUserPlaceholder";
import {
  addToast,
  ToastType,
  useToasts,
} from "@components/ToastManager/Toasts";
import { css } from "@emotion/react";
import React, { useState, useContext, useEffect } from "react";

import { spacing } from "@every.org/common/src/display/spacing";
import {
  getRoutePath,
  URLFormat,
  ClientRouteName,
} from "@every.org/common/src/helpers/clientRoutes";

import { PageLoadingIndicator } from "src/components/LoadingIndicator";
import {
  LoginForm,
  REDIRECT_URL_QUERY_PARAM,
} from "src/components/Login/LoginForm";
import { Redirect } from "src/components/Redirect";
import { DefaultPageLayout } from "src/components/layout/DefaultPageLayout";
import { PageSection } from "src/components/layout/PageSection";
import { AuthContext } from "src/context/AuthContext";
import {
  isValidLoginRedirectUrl,
  logoutAuthState,
} from "src/context/AuthContext/actions";
import { AuthStatus } from "src/context/AuthContext/types";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { TwoStepLoginForm } from "src/pages/Login/TwoStepLoginForm";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { useStatSigLayer } from "src/utility/abtesting";
import { getAndDecodeSearchParam } from "src/utility/helpers";

const RESET_AUTH_QUERY_PARAM = "resetAuth";

export function LoginPage() {
  const authState = useContext(AuthContext);
  const [redirectTo, setRedirectTo] = useState<string>();
  const { search } = useEdoRouter();
  const toastsState = useToasts();
  const showNewFlow = useStatSigLayer("two_step_login_layer").get(
    "newFlow",
    false
  );

  useEffect(() => {
    // handle redirects from auth0 errors by resetting the auth state
    // https://github.com/everydotorg/every.org/issues/13583
    const urlSearchParams = new URLSearchParams(search);
    const resetAuth = getAndDecodeSearchParam(
      urlSearchParams,
      RESET_AUTH_QUERY_PARAM
    );
    if (resetAuth) {
      logoutAuthState();
      addToast(ToastType.AUTH_ERROR);
      return;
    }

    if (authState.status !== AuthStatus.LOGGED_IN) {
      return;
    }

    const searchRedirect = getAndDecodeSearchParam(
      urlSearchParams,
      REDIRECT_URL_QUERY_PARAM
    );
    if (searchRedirect && isValidLoginRedirectUrl({ url: searchRedirect })) {
      setRedirectTo(searchRedirect);
      return;
    }
    setRedirectTo(
      getRoutePath({
        format: URLFormat.RELATIVE,
        name: ClientRouteName.HOME,
      })
    );
  }, [authState.status, search, toastsState]);
  if (authState.status === AuthStatus.LOADING) {
    return <PageLoadingIndicator />;
  }
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <DefaultPageLayout
      pageTitle={"Log in"}
      pageContentCss={{ paddingTop: spacing.l }}
      disableFooterMargin
    >
      <PageSection>
        <Card
          shadow
          css={cssForMediaSize({
            min: MediaSize.X_LARGE,
            css: { padding: spacing.xl },
          })}
        >
          <h1>Log in</h1>
          <div
            css={cssForMediaSize({
              min: MediaSize.X_LARGE,
              css: css`
                display: flex;
                justify-content: space-between;
              `,
            })}
          >
            {showNewFlow ? <TwoStepLoginForm /> : <LoginForm />}
            <NewUserPlaceholder
              width={495}
              height={405}
              margin={spacing.xxxxl}
            />
          </div>
        </Card>
      </PageSection>
    </DefaultPageLayout>
  );
}
