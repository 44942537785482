import { ErrorMessage } from "@components/ErrorMessage";
import { Form, Align } from "@components/Form";
import { ContinueWithSocial } from "@components/Login/LoginForm";
import { QuestionAnswerLink } from "@components/QuestionAnswerLink";
import { TextInput, TextInputType } from "@components/TextInput";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState, useEffect } from "react";

import { ClientRouteName } from "@every.org/common/src/helpers/clientRoutes";

import { getWebAuth } from "src/context/AuthContext/WebAuth";
import {
  emailAuth0Login,
  EmailAuth0LoginStatus,
} from "src/context/AuthContext/actions";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { getAndDecodeSearchParam } from "src/utility/helpers";

const StyledForm = styled(Form)`
  margin: ${spacing.xl} 0;
  width: 100%;
  max-width: 570px;
`;

const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: ${spacing.oldM};
  }
`;
const EmailInput = styled(TextInput)`
  margin-bottom: ${spacing.oldM};
`;

enum LoginSteps {
  EMAIL_STEP = "EMAIL_STEP",
  PASSWORD_STEP = "PASSWORD_STEP",
}

export const REDIRECT_URL_QUERY_PARAM = "redirectUrl";

export function TwoStepLoginForm() {
  useEffect(() => {
    getWebAuth(); // trigger it to be loaded before user hits login
  }, []);
  const { search } = useEdoRouter();
  const searchParams = new URLSearchParams(search);

  const [email, setEmail] = useState(
    getAndDecodeSearchParam(searchParams, "email") || ""
  );
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>();

  const redirectUrl = getAndDecodeSearchParam(
    searchParams,
    REDIRECT_URL_QUERY_PARAM
  );
  const [step, setStep] = useState(LoginSteps.EMAIL_STEP);

  const handleNext = () => {
    if (!email) {
      setError("Please enter your email");
    } else {
      setError(undefined);
      setStep(LoginSteps.PASSWORD_STEP);
    }
  };

  const logIn = async () => {
    if (!password) {
      setError("Please enter password");
    } else {
      try {
        const result = await emailAuth0Login({
          email,
          password,
          redirectUrl,
        });
        if (result.status === EmailAuth0LoginStatus.ERROR) {
          setError(result.errorMessage);
        }
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
      }
    }
  };

  const formMessage = getAndDecodeSearchParam(searchParams, "message");

  const emailStepContent = (
    <div css={verticalStackCss.oldM}>
      <ContinueWithSocial showFacebook />
      <EmailInput
        id="email"
        data-tname="Login-EmailInput"
        autoComplete="username"
        type={TextInputType.EMAIL}
        name="email"
        labelText="Email"
        value={email}
        required
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmail(e.target.value);
        }}
        collapseDescriptionSpace
      />
      <TextInput
        id="password"
        data-tname="Login-PasswordInput"
        autoComplete="current-password"
        type={TextInputType.PASSWORD}
        labelText="Password"
        name="password"
        value={password}
        css={css`
          display: none;
        `}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(e.target.value);
        }}
      />
    </div>
  );

  const passwordStepContent = (
    <div css={verticalStackCss.oldM}>
      <div
        css={css`
          width: 100%;
          padding-bottom: ${spacing.s};
        `}
      >
        <h3>{email}</h3>
      </div>
      <TextInput
        id="password"
        data-tname="Login-PasswordInput"
        autoComplete="current-password"
        type={TextInputType.PASSWORD}
        labelText="Password"
        name="password"
        value={password}
        required
        css={css`
          inpit {
            width: 100%;
          }
        `}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPassword(e.target.value);
        }}
        collapseDescriptionSpace
      />
    </div>
  );

  return (
    <StyledForm
      onSubmit={step === LoginSteps.EMAIL_STEP ? handleNext : logIn}
      submitButtonContent={
        step === LoginSteps.EMAIL_STEP ? "Next" : "Log in with email"
      }
      data-tname="logInForm"
      alignActions={Align.LEFT}
      subHeadingText={formMessage}
      onBack={
        step === LoginSteps.PASSWORD_STEP
          ? () => {
              setStep(LoginSteps.EMAIL_STEP);
            }
          : undefined
      }
    >
      <LoginFormContainer>
        {step === LoginSteps.EMAIL_STEP
          ? emailStepContent
          : passwordStepContent}
        <ErrorMessage text={error} />
        <QuestionAnswerLink
          question="Forgot password?"
          answer="Reset"
          data-tname="resetPasswordPrompt"
          routeName={ClientRouteName.RESET_PASSWORD}
        />
        <QuestionAnswerLink
          question="New to Every.org?"
          answer="Sign up now"
          data-tname="signupPrompt"
          routeName={ClientRouteName.CHOOSE_ACCOUNT}
          routeQuery={redirectUrl ? { redirectUrl } : {}}
        />
      </LoginFormContainer>
    </StyledForm>
  );
}
