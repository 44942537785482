import { CloudinaryImg } from "@components/CloudinaryImg";
import { css } from "@emotion/react";

import { ImageFormat } from "@every.org/common/src/helpers/cloudinary";

import { MediaSize, cssForMediaSize } from "src/theme/mediaQueries";

const SIGNUP_IMAGE_CLOUDINARY_ID = "signup_r8mgl8";

export const NewUserPlaceholder = ({
  width,
  height,
  margin = "0",
  hideSize = MediaSize.X_LARGE,
}: {
  width: number;
  height: number;
  margin?: string;
  hideSize?: Exclude<MediaSize, MediaSize.XX_LARGE>;
}) => {
  return (
    <div
      css={css`
        height: ${height}px;
        width: ${width}px;
        margin-top: ${margin};
        ${cssForMediaSize({
          max: hideSize,
          css: { display: "none" },
        })}
      `}
    >
      <CloudinaryImg
        cloudinaryId={SIGNUP_IMAGE_CLOUDINARY_ID}
        imageFormat={ImageFormat.svg}
        dimensions={{
          height,
          width,
        }}
        alt="Signup image"
      />
    </div>
  );
};
